<div class="flex flex-col sm:flex-row w-full h-full bg-color-bg-page">
    <!-- Nav Menu -->
    <ng-component *ngTemplateOutlet="SideNav"></ng-component>
    <ng-component *ngTemplateOutlet="TopNav"></ng-component>
    <!-- Content -->
    <div class="w-full h-full">
        <router-outlet></router-outlet>
    </div>
</div>

<ng-template #SideNav>
    <div class="sm:flex flex-col flex-none w-72 h-full hidden nav-shadow z-50">
        <span aria-hidden="true" class="pt-4"></span>
        <!-- Stactize Logo -->
        <img src="./assets/images/stactize-logo.svg" alt="Stactize logo" class="h-16 cursor-pointer"
            routerLink="/dashboard" />
        <span aria-hidden="true" class="pt-10"></span>
        <!-- Nav Link List -->
        <div class="flex flex-col flex-grow max-h-full px-6 overflow-y-auto">
            @for (link of filteredLinks$|async; track link.displayName) {
            <a class="flex flex-row flex-none h-10 items-center text-actionable-primary text-md font-semibold"
                routerLink="{{link.route}}" routerLinkActive="font-bold underline"
                [routerLinkActiveOptions]="{exact: false}">
                <mat-icon [fontIcon]="link.icon!"></mat-icon>
                <span aria-hidden="true" class="pl-4"></span>
                <span attr.aria-label="Link to {{link.displayName}}">{{link.displayName}}</span>
            </a>
            }
        </div>
        <div class="flex flex-col justify-end">
            <app-user-login-info></app-user-login-info>
        </div>
    </div>
</ng-template>

<ng-template #TopNav>
    <div class="flex sm:hidden">
        <div class="flex flex-row w-full p-1 shadow items-center">
            <button class="p-1" [matMenuTriggerFor]="menu"><mat-icon fontIcon="menu"></mat-icon></button>
            <img src="./assets/images/stactize-logo-sm.svg" class="h-6 cursor-pointer flex-auto" alt="Stactize logo" />
            <button class="p-1" [matMenuTriggerFor]="account"><mat-icon fontIcon="perm_identity"></mat-icon></button>
        </div>
        <mat-menu #menu="matMenu">
            @for (link of filteredLinks$|async; track link.displayName) {
            <button mat-menu-item routerLink="{{link.route}}"><mat-icon
                    [fontIcon]="link.icon!"></mat-icon>{{link.displayName}}</button>
            }
        </mat-menu>
        <mat-menu #account="matMenu">
            <app-user-login-info [alwaysExpanded]="true"></app-user-login-info>
        </mat-menu>
    </div>
</ng-template>