import { Component, computed, inject, Injector, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AuthService, FeatureFlagService, role } from '@stactize/domain';
import { filter } from 'rxjs';
import { UpperCasePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { FeatureFlags } from '../../core/constants/feature-flag.const';
@Component({
  selector: 'app-user-login-info',
  standalone: true,
  imports: [MatIconModule, UpperCasePipe, RouterModule],
  templateUrl: './user-login-info.component.html',
  styleUrl: './user-login-info.component.scss'
})
export class UserLoginInfoComponent {
  private readonly authService = inject(AuthService);
  private readonly featureFlagService = inject(FeatureFlagService)
  private readonly injector = inject(Injector);
  private readonly featureFlags = toSignal(this.featureFlagService.getFeatureFlags(), { injector: this.injector });

  public isExpanded = false;
  public userName: string = '';
  public userRole: string = '';
  public userInitials: string = '';

  public userProfileFeatureEnabled = computed(() => this.featureFlags()?.find(f => f.name == FeatureFlags.uiElementUserProfile)?.isEnabled ?? false);
  public userManagementFeatureEnabled = computed(() => this.featureFlags()?.find(f => f.name == FeatureFlags.uiElementUserManagement)?.isEnabled ?? false);
  public externalConnectionsFeatureEnabled = computed(() => this.featureFlags()?.find(f => f.name == FeatureFlags.uiElementEntraIdCredentials)?.isEnabled ?? false);

  @Input()
  public alwaysExpanded: boolean = false;

  constructor() {
    this.authService.getUser().pipe(filter(u => !!u)).subscribe(user => {
      this.userName = `${user.firstName ?? ''} ${user.lastName ?? ''}`
      this.userInitials = (user.firstName?.charAt(0) ?? '') + (user.lastName?.charAt(0) ?? '');
      this.userRole = this.mapRolesToDisplayName(user.roles ?? []);
    })
  }

  private mapRolesToDisplayName(roles: string[]): string {
    if (roles.includes(role.admin)) {
      return 'Admin';
    }

    if (roles.includes(role.publisherAdmin)) {
      return 'Publisher Admin';
    }

    if (roles.includes(role.publisher)) {
      return 'Publisher';
    }

    return '';
  }

  toggle(): void {
    this.isExpanded = !this.isExpanded
  }

  logOut(): void {
    this.authService.logout();
  }
}
