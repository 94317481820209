import { Routes } from '@angular/router';
import { LoginComponent, NotFoundComponent, authGuard, featureFlagGuard, role } from '../../../domain/src/public-api';
import { NavLayoutComponent } from './layouts/nav-layout/nav-layout.component';
import { FeatureFlags } from './core/constants/feature-flag.const';

// Lazy load single component:
// loadComponent: () => import('./modules/application/application.component').then(m => m.ApplicationComponent)

// Lazy load multiple components & their routes:
// loadChildren: () => import('./modules/application/application.routes')

export const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'forbidden',
        component: LoginComponent
    },
    {
        path: 'notfound',
        component: NotFoundComponent
    },
    {
        path: '',
        component: NavLayoutComponent,
        canActivate: [authGuard],
        data: { roles: [role.admin, role.publisherAdmin, role.publisher] },
        children: [
            {
                path: 'dashboard',
                loadComponent: () => import('./modules/dashboard/dashboard.component').then(m => m.DashboardComponent)
            },
            {
                path: 'applications',
                loadChildren: () => import('./modules/application/application.routes'),
                canActivate: [featureFlagGuard],
                data: { requiredFeatureFlag: FeatureFlags.uiPageApplication },
            },
            {
                path: 'publishers',
                loadComponent: () => import('./modules/publisher/publisher.component').then(m => m.PublisherComponent)
            },
            {
                path: 'subscriptions',
                loadChildren: () => import('./modules/subscription/subscription.routes'),
                canActivate: [featureFlagGuard],
                data: { requiredFeatureFlag: FeatureFlags.uiPageSubscription }
            },
            {
                path: 'private-offer',
                loadComponent: () => import('./modules/private-offer/private-offer.component').then(m => m.PrivateOfferComponent),
                canActivate: [featureFlagGuard],
                data: { requiredFeatureFlag: FeatureFlags.uiPagePrivateOffer }
            },
            {
                path: 'users',
                loadChildren: () => import('./modules/user/user.routes'),
                canActivate: [featureFlagGuard, authGuard],
                data: { requiredFeatureFlag: FeatureFlags.uiPageUser, roles: [role.admin, role.publisherAdmin] }

            },
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            {
                path: '**',
                component: NotFoundComponent
            },
        ]
    },
    {
        path: '**',
        component: NotFoundComponent
    }
];
