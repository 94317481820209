import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FeatureFlagService } from '../../services/feature-flag/feature-flag.service';
import { map } from 'rxjs';

export const featureFlagGuard: CanActivateFn = (route, state) => {
  const featureFlagService = inject(FeatureFlagService);
  const router = inject(Router);

  const requiredFeatureFlag: string = route.data['requiredFeatureFlag'];

  return featureFlagService.isFeatureEnabled(requiredFeatureFlag).pipe(
    map(isFeatureEnabled => {
      return !isFeatureEnabled ? router.createUrlTree(['/notfound']) : isFeatureEnabled;
    })
  );
};
